<template>
  <h1 class="mb-0"><a href="/">expl's gist system</a></h1>
  <p class="mt-0">Please use carefully. Thanks. <span class="about">v0.0.2</span></p>

  <div class="container" v-if="getUrl() == '/'">
    <div class="form">
      <p class="bold">Gist form</p>
      <form id="createGist" @submit='createGist'>
        <!--<div class="form-item">
          <input type="text" name="title" class="form-item-entity" placeholder="Enter title" minlength="5" required/>
        </div>-->
        <div class="form-item">
          <textarea name="source" class="form-item-entity" placeholder="Enter your code here" minlength="5" required></textarea>
        </div>
        <div class="form-item">
          <select name="status" class="form-item-entity">
            <option value="public" selected>public</option>
            <option value="private">private</option>
          </select>
        </div>
        <div class="form-item">
          <button type="submit" class="form-item-entity">Create gist</button>
        </div>
      </form>
    </div>

    <div class="last-gists">
      <p class="bold">Latest gists</p>
      <ul>
        <li v-for="gist in gists" v-bind:key="gist.id">
          <a v-bind:href="'/'+ gist.hash">Gist from {{ gist.date }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="container detail" v-else>
    <p>Created from {{ gist.date }} (views {{ gist.views }})</p>
    <p class="mt-0 mb-0"><a href="javascript:void(0);" @click='copyCode'>Copy source code</a></p>
    <div class="form-item">
      <textarea id="source" v-model="source" class="form-item-entity w-unset" placeholder="Enter your code here" readonly></textarea>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  components: {
    /*HelloWorld*/
  },
  data() {
    return {
      gists: [],
      gist: [],
      url: null,
      source: null,
    };
  },
  methods: {
    createGist(e) {
      e.preventDefault();

      // Post data
      const requestData = new URLSearchParams();
      requestData.append('source', document.querySelector('textarea[name=source]').value);
      requestData.append('status', document.querySelector('select[name=status]').value);

      const requestConfig = {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
      };
      axios.post('https://gist.3xpl.ru/api/v1/createGist', requestData, requestConfig)
      .then(response => {
          if(response.data.success && response.data.result.hash)
          {
            document.getElementById('createGist').reset();
            location = response.data.result.hash;
          }
      })
      .catch(e => {
        this.error=e;
        console.error(e);
      });
    },
    getUrl() {
      return this.url;
    },
    copyCode() {
      document.querySelector('#source').select();
      document.execCommand('copy');
    }
  },
  mounted() {
    this.url = window.location.pathname;

    if(this.url == '/')
    {
      axios.get('https://gist.3xpl.ru/api/v1/getGists')
      .then(response => {
          if(response.data.success && response.data.result.length > 0)
            this.gists = response.data.result;
      })
      .catch(e => {
        this.error=e;
        console.error(e);
      });
    }
    else
    {
      axios.get('https://gist.3xpl.ru/api/v1/getGist' + this.url)
      .then(response => {
        if(response.data.success && response.data.result)
        {
          this.gist = response.data.result;
          this.source = response.data.result.source;
        }
      })
      .catch(e => {
        this.error=e;
        console.error(e);
      });
    }
  }
}
</script>

<style>
  body {
    background-color:ghostwhite;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
  }
  a {
    text-decoration: none;
    color: #c03;
  }
  a:hover {
    color: #999;
  }
  .about {
    color: #999;
    font-size: 12px;
  }
  .w-unset {
    max-width: unset !important;
  }
  .detail {
    display: block !important;
  }
  .detail textarea {
    height: 350px;
  }
  .container {
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1000px;
    width: 100%;
  }
  .last-gists, .form {
    max-width: 500px;
    width: 100%;
    height: 320px;
    top: 0;
  }
  .form-item {
    display: block;
    margin-top: 5px;
  }
  .form-item textarea {
    min-height: 150px;
  }
  .form-item-entity {
    max-width: 450px;
    width: 100%;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .bold {
    font-weight: 700;
  }
</style>